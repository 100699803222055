import React, { Fragment } from "react";
import renderAst from "../utils/ast-compiler";
import Layout from "../components/layout";
import { FormattedMessage } from "react-intl";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const TitleDescription = ({ node, left }) => (
  <Fragment>
    {left ? (
      <>
        <h2 className="text-xl leading-7 font-semibold text-gray-900">{node.frontmatter.title}</h2>
        <div className="lg:grid lg:grid-cols-2 lg:gap-x-16">
          <div className="mt-3 prose">{renderAst(node.htmlAst)}</div>
          {node.frontmatter.image &&
            node.frontmatter.image.src &&
            node.frontmatter.image.src.childImageSharp && (
              <div className="mt-8">
                <GatsbyImage
                  className="rounded-md"
                  title={node.frontmatter.image.alt}
                  alt={node.frontmatter.image.alt}
                  image={node.frontmatter.image.src.childImageSharp.gatsbyImageData}
                />
              </div>
            )}
        </div>
      </>
    ) : (
      <>
        <div className="hidden lg:block lg:grid lg:grid-cols-2 lg:gap-x-16">
          <div></div>
          <h2 className="text-xl leading-7 font-semibold text-gray-900">
            {node.frontmatter.title}
          </h2>
        </div>
        <div className="block lg:hidden">
          <h2 className="text-xl leading-7 font-semibold text-gray-900">
            {node.frontmatter.title}
          </h2>
          <div></div>
        </div>
        <div className="lg:grid lg:grid-cols-2 lg:gap-x-16">
          <div className="block lg:hidden mt-3 prose">{renderAst(node.htmlAst)}</div>

          {node.frontmatter.image &&
            node.frontmatter.image.src &&
            node.frontmatter.image.src.childImageSharp && (
              <div className="mt-8">
                <GatsbyImage
                  className="rounded-md"
                  title={node.frontmatter.image.alt}
                  alt={node.frontmatter.image.alt}
                  image={node.frontmatter.image.src.childImageSharp.gatsbyImageData}
                />
              </div>
            )}
          <div className="hidden lg:block mt-3 prose">{renderAst(node.htmlAst)}</div>
        </div>
      </>
    )}
  </Fragment>
);

const FeaturePage = ({ pageContext: { language, title, id }, data }) => {
  return (
    <Layout locale={language} title={title} pageId={id}>
      <div className="bg-white">
        <div className="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-base leading-6 font-semibold text-pink-600 tracking-wide uppercase">
              <FormattedMessage id="features.title" />
            </h1>
            <p className="mt-1 text-4xl leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl">
              <FormattedMessage id="features.pre-title" />
            </p>
            <p className="max-w-xl mt-5 mx-auto text-xl leading-7 text-gray-500">
              <FormattedMessage id="features.post-title" />
            </p>
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto mt-8 px-4 sm:px-6  lg:px-8">
        {data.features &&
          data.features.edges.map((feature, index) => (
            <div className="mb-16" key={`feature_${index}`}>
              <TitleDescription left={index % 2 === 0} node={feature.node} />
            </div>
          ))}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query FeaturePageQuery($language: String!) {
    features: allMarkdownRemark(
      filter: { frontmatter: { tags: { eq: "feature" } }, fields: { language: { eq: $language } } }
      sort: { fields: [frontmatter___sortIndex], order: ASC }
    ) {
      edges {
        node {
          htmlAst
          frontmatter {
            title
            image {
              src {
                publicURL
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 400, placeholder: TRACED_SVG)
                }
              }
              alt
            }
            youtube
          }
        }
      }
    }
  }
`;

export default FeaturePage;
